/* eslint-disable jsx-a11y/anchor-is-valid, react-hooks/exhaustive-deps */
import Login from './user/Login';
import { ToastContainer, toast } from 'react-toastify';
import { Routes, Route } from "react-router-dom";
import {  useCookies } from 'react-cookie';
import { useState, useEffect } from 'react';
import {Helmet} from "react-helmet";
import axios from 'axios';
import React from 'react';

import 'react-toastify/dist/ReactToastify.min.css';

import Layout from './Layout';

// import UserAdd from './pages/user/UserAdd';
// import User from './pages/user/User';

const User = React.lazy(() => import('./pages/user/User'));
const UserAdd = React.lazy(() => import('./pages/user/UserAdd'));
const Categories = React.lazy(() => import('./pages/categories/Categories'));
const CategoriesAdd = React.lazy(() => import('./pages/categories/CategoriesAdd'));
const Pos = React.lazy(() => import('./pages/pos/Pos'));
const PosAdd = React.lazy(() => import('./pages/pos/PosAdd'));
const Products = React.lazy(() => import('./pages/products/Products'));
const ProductsAdd = React.lazy(() => import('./pages/products/ProductsAdd'));

function App() {

  const [theme, setTheme] = useState(false);

  const err = (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }

  const suc = (message) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }


  const switchTheme = (event) => {
    event.preventDefault();
    setCookie('theme', !theme, { path: '/' });
    setTheme(!theme);
    document.body.classList.toggle('dark-theme');
  }

  const CDN = (file) => {
    return `${process.env.REACT_APP_CDN_HOST}/${file}`;
  }


  // async api request
  const API = async (method, path, data, params = {}) => { 
    let token = cookies.token ? cookies.token : '';
    
     //console.log(data);

      let url_params = '';
      if (params) {
        url_params = '?' + Object.keys(params).map((key) => {
          return key + '=' + params[key];
        }).join('&');

      }

      return await axios({
        method: method,
        url: `${process.env.REACT_APP_API_HOST}/api/${path}/${url_params}`,
        data: data,
        headers: {
          'Content-Type': 'multipart/form-data',
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      }).catch((error) => {
        console.error('API error', error);
        if (error?.response?.data?.message) {
          let errors = JSON.stringify(error.response.data.data);
          err(error.response.data.message + ' ' + errors);
        }

      });
  }
      
 

  const handleLogin = async (loginData) => {
    await API('post', 'login', loginData).then((response) => {

      if (response?.data?.data?.token) {
        setCookie('token', response.data.data.token, { path: '/' });

        //write name to local storage
        localStorage.setItem('username', response.data.data.name);
        localStorage.setItem('userrole', response.data.data.role);

        //refresh page
        window.location.reload();
      }
    });
  }


  const handleRegistration = async (regData) => {
    await API('post', 'register', regData).then((response) => {
      if (response && response.data  && response.data.message) {
        suc(response.data.message);
        //wait 2s and refresh the page
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    });
  }

 

  // check theme cookie 
  useEffect(() => {
    if (cookies.theme) {
      document.body.classList.add('dark-theme');
      setTheme(true);
    } 
  }, []);

  const [cookies, setCookie] = useCookies(['token, theme']);

  return (cookies.token ? (
    <Routes>
         
      <Route path="/" element={<Layout Helmet={Helmet} setCookie={setCookie} ToastContainer={ToastContainer} switchTheme={switchTheme} API={API} CDN={CDN} />} >
        
        <Route path="user" element={<User Helmet={ Helmet } API={ API } />} />
        <Route path="user/add" element={<UserAdd Helmet={Helmet} onReg={handleRegistration} />} />
        
        <Route path="pos" element={<Pos Helmet={Helmet} API={API} suc={ suc }/>} />
        <Route path="pos/add" element={<PosAdd Helmet={Helmet} API={API} suc={ suc }/>} />

        <Route path="categories" element={<Categories Helmet={Helmet} API={API} CDN={CDN} />} />
        <Route path="categories/edit/:editID" element={<CategoriesAdd Helmet={Helmet} API={API} suc={suc} CDN={CDN} />} />
        <Route path="categories/:urlPage" element={<Categories Helmet={ Helmet } API={API} CDN={CDN} />} />
        <Route path="categories/add"   element={<CategoriesAdd Helmet={ Helmet } API={API} suc={ suc }/>} />
        
        <Route path="products" element={<Products Helmet={Helmet} API={API} CDN={CDN} />} />
        <Route path="products/edit/:editID" element={<ProductsAdd Helmet={Helmet} API={API} suc={suc} CDN={CDN} />} />
        <Route path="products/:urlPage" element={<Products Helmet={Helmet} API={API} CDN={CDN} />} />
        <Route path="products/add"   element={<ProductsAdd Helmet={Helmet} API={API} suc={suc} CDN={CDN} />} />
        
        
        

        <Route path="*" element={<img alt="404" src="/img/404.png"/>} />
      </Route>
    </Routes>
  ) : (<div id="wrapper"><Login onLogin={handleLogin} /></div>));
}

export default App;
