


import React, { useState } from 'react';
import { NavLink,useNavigate } from 'react-router-dom';

function MenuTop({ switchTheme, setCookie, API, CDN }) {

    const navigate = useNavigate();
    const [search, setSearch] = useState('');
    const [searchResults, setSearchResults] = useState([]);

    const [focused, setFocused] = React.useState(false)
    const onFocus = () => setFocused(true)
    const onBlur = () => setFocused(false)

    const doLogout = () => {
        setCookie('token', '', { path: '/' });
        window.location.reload();
    }

    const doSearch = (search) => {
        setSearch(search);
        if (search.length > 2) {
            API('get', 'search', [], { 'q': search, 'limit': 5 }).then((response) => {
                if (response && response.data) {
                    console.log(response.data);
                    setSearchResults(response.data.data);
                }
            });
        }
    }

    const searchClick = (folder, id) => { 
        //setSearch('');
        //setSearchResults([]);
        setFocused(false);
        console.log(folder + id);
        navigate(folder + id);
    };



    // get user role from local storage
    let userRole = localStorage.getItem('userrole');
    let userName = localStorage.getItem('username');

    return (
        <div className="header-dashboard">
            <div className="wrap">
                <div className="header-left">
                    <a href="index.html">
                        <img className="" id="logo_header_mobile" alt="" src="images/logo/logo.png" data-light="images/logo/logo.png" data-dark="images/logo/logo-dark.png" data-width="154px" data-height="52px" data-retina="images/logo/logo@2x.png" />
                    </a>
                    <div className="button-show-hide">
                        <i className="icon-menu-left"></i>
                    </div>
                    <form className="form-search flex-grow" onFocus={onFocus} onBlur={onBlur}>
                        <fieldset className="name">
                            <input type="text" placeholder="Search here..." className="show-search" name="name" tabIndex="2" aria-required="true" required="" autoComplete="one-time-code" value={search} onChange={(e) => doSearch(e.target.value)} />
                        </fieldset>
                        <div className="button-submit">
                            <button className="" type="submit"><i className="icon-search"></i></button>
                        </div>
                        <div className={focused ? 'box-content-search active' : 'box-content-search'} id="box-content-search">

                            {searchResults && searchResults.count > 0 ? (
                                <div>

                                    {searchResults && searchResults.products.length > 0 && (
                                        <ul className="mb-24">
                                            <li className="mb-14">
                                                <div className="body-title">Products</div>
                                            </li>
                                            <li className="mb-14">
                                                <div className="divider"></div>
                                            </li>

                                            <li>
                                                <ul>
                                                    {searchResults.products.map((product, index) => (
                                                        <div onClick={() => { searchClick('/products/edit/', product.id)}} className="product-item gap14 mb-10" key={index}>
                                                            <div className="image" style={{ backgroundImage: `url(${CDN(JSON.parse(product.gallery)[0])})` }}>

                                                            </div>
                                                            <div className="flex items-center justify-between gap20 flex-grow">
                                                                <div className="search-line body-text">

                                                                    <span>{product.name} ({product.sku})</span>
                                                                    <span><i className="icon-dollar-sign"></i>{product.price}</span>
                                                                    <span><i className="icon-box"></i>{product.stock}</span>
                                                                    <span><i className="icon-power"></i>{product.status == 1 ? 'Active' : 'Disabled'}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}


                                                </ul>
                                            </li>
                                        </ul>)}


                                    {searchResults && searchResults.categories.length > 0 && (
                                        <ul className="mb-24">
                                            <li className="mb-14">
                                                <div className="body-title">Categories</div>
                                            </li>
                                            <li className="mb-14">
                                                <div className="divider"></div>
                                            </li>

                                            <li>
                                                <ul>
                                                    {searchResults.categories.map((product, index) => (
                                                        <div onClick={() => { searchClick('/categories/edit/', product.id) }} className="product-item gap14 mb-10" key={index}>
                                                            <div className="image" style={{ backgroundImage: `url(${CDN(product.image)})` }}>
                                                            </div>
                                                            <div className="flex items-center justify-between gap20 flex-grow">
                                                                <div className="search-line body-text">
                                                                    <span>{product.name}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}


                                                </ul>
                                            </li>
                                        </ul>)}
                                </div>
                            ) : (
                                <div className="no-keywords">
                                    {searchResults.query ? (<p>Sorry, nothing was found for <b>{searchResults.query}</b></p>) : (
                                        <p>Please type something in the search box. 3 letters or more.</p>)}

                                </div>
                            )}
                        </div>
                    </form>
                </div>
                <div className="header-grid">

                    <div className="header-item button-dark-light" onClick={switchTheme}>
                        <i className="icon-moon"></i>
                    </div>

                    <div className="popup-wrap message type-header">
                        <div className="dropdown">
                            <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                                <span className="header-item">
                                    <span className="text-tiny">1</span>
                                    <i className="icon-message-square"></i>
                                </span>
                            </button>
                            <ul className="dropdown-menu dropdown-menu-end has-content" aria-labelledby="dropdownMenuButton2" >
                                <li>
                                    <h6>Notifications</h6>
                                </li>
                                <li>
                                    <div className="message-item item-1">
                                        <div className="image">
                                            <i className="icon-noti-1"></i>
                                        </div>
                                        <div>
                                            <div className="body-title-2">Discount available</div>
                                            <div className="text-tiny">Morbi sapien massa, ultricies at rhoncus at, ullamcorper nec diam</div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="message-item item-2">
                                        <div className="image">
                                            <i className="icon-noti-2"></i>
                                        </div>
                                        <div>
                                            <div className="body-title-2">Account has been verified</div>
                                            <div className="text-tiny">Mauris libero ex, iaculis vitae rhoncus et</div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="message-item item-3">
                                        <div className="image">
                                            <i className="icon-noti-3"></i>
                                        </div>
                                        <div>
                                            <div className="body-title-2">Order shipped successfully</div>
                                            <div className="text-tiny">Integer aliquam eros nec sollicitudin sollicitudin</div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="message-item item-4">
                                        <div className="image">
                                            <i className="icon-noti-4"></i>
                                        </div>
                                        <div>
                                            <div className="body-title-2">Order pending: <span>ID 305830</span></div>
                                            <div className="text-tiny">Ultricies at rhoncus at ullamcorper</div>
                                        </div>
                                    </div>
                                </li>
                                <li><a href="#" className="tf-button w-full">View all</a></li>
                            </ul>
                        </div>
                    </div>

                    <div className="popup-wrap user type-header">
                        <div className="dropdown">
                            <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton3" data-bs-toggle="dropdown" aria-expanded="false">
                                <span className="header-user wg-user">
                                    <span className="image">
                                        <img src="images/avatar/user-1.png" alt="" />
                                    </span>
                                    <span className="flex flex-column">
                                        <span className="body-title mb-2">{userName}</span>

                                        <span className="text-tiny">
                                            {userRole}
                                        </span>
                                    </span>
                                </span>
                            </button>
                            <ul className="dropdown-menu dropdown-menu-end has-content" aria-labelledby="dropdownMenuButton3" >
                                <li>
                                    <a href="#" className="user-item">
                                        <div className="icon">
                                            <i className="icon-log-out"></i>
                                        </div>
                                        <div className="body-title-2" onClick={() => { doLogout() }}>Log out</div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MenuTop;
