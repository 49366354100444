/* eslint-disable jsx-a11y/anchor-is-valid, react-hooks/exhaustive-deps */
import { NavLink } from "react-router-dom";
import { useState, useEffect } from 'react';

function MenuLeft() {

    const [menuState, setMenustate] = useState([]);

    const togglemenu = (e) => {
        let newMenuState = [...menuState];
        newMenuState[e] = !newMenuState[e];
        setMenustate(newMenuState);
    }


    useEffect(() => {
        // Pre open menu based on url

        //check if url has categories
        if (window.location.href.indexOf('/categories') > -1) {
            let newMenuState = [...menuState];
            newMenuState[2] = true;
            setMenustate(newMenuState);
        }

        //check if url has user
        if (window.location.href.indexOf('/user') > -1) {
            let newMenuState = [...menuState];
            newMenuState[3] = true;
            setMenustate(newMenuState);
        }

        //check if url has user
        if (window.location.href.indexOf('/pos') > -1) {
            let newMenuState = [...menuState];
            newMenuState[5] = true;
            setMenustate(newMenuState);
        }

        //check if url has products
        if (window.location.href.indexOf('/products') > -1) {
            let newMenuState = [...menuState];
            newMenuState[1] = true;
            setMenustate(newMenuState);
        }



    }, []);


    return (
        <div className="section-menu-left">
            <div className="box-logo">
                <div href="index.html" id="site-logo-inner">
                    <h3>SKU<span>CLOUD</span></h3>
                </div>
                <div className="button-show-hide">
                    <i className="icon-menu-left"></i>
                </div>
            </div>
            <div className="center">

                <div className="center-item">

                    <ul className="menu-list">
                        <li className={menuState[1] ? 'menu-item has-children active' : 'menu-item has-children'} >
                            <a href="#" onClick={() => { togglemenu(1) }}>
                                <div className="icon"><i className="icon-shopping-bag"></i></div>
                                <div className="text">Products</div>
                            </a>
                            <ul className="sub-menu">
                                <li className="sub-menu-item">
                                    <NavLink to="/products">
                                        <div className="text">Product List</div>
                                    </NavLink>
                                </li>
                                <li className="sub-menu-item">
                                    <NavLink to="/products/add">
                                        <div className="text">Add Product</div>
                                    </NavLink>
                                </li>
                            </ul>
                        </li>

                        <li className={menuState[2] ? 'menu-item has-children active' : 'menu-item has-children'} >
                            <a href="#" onClick={() => { togglemenu(2) }}>
                                <div className="icon"><i className="icon-layers"></i></div>
                                <div className="text">Category</div>
                            </a>
                            <ul className="sub-menu">
                                <li className="sub-menu-item">
                                    <NavLink to="/categories">
                                        <div className="text">Category list</div>
                                    </NavLink>
                                </li>
                                <li className="sub-menu-item">
                                    <NavLink to="/categories/add">
                                        <div className="text">New category</div>
                                    </NavLink>
                                </li>
                            </ul>
                        </li>

                        <li className={menuState[4] ? 'menu-item has-children active' : 'menu-item has-children'} >
                            <a href="#" onClick={() => { togglemenu(4) }}>
                                <div className="icon"><i className="icon-box"></i></div>
                                <div className="text">Order</div>
                            </a>
                            <ul className="sub-menu">
                                <li className="sub-menu-item">
                                    <a href="oder-list.html" className="">
                                        <div className="text">Order list</div>
                                    </a>
                                </li>
                                <li className="sub-menu-item">
                                    <a href="oder-detail.html" className="">
                                        <div className="text">Order detail</div>
                                    </a>
                                </li>
                                <li className="sub-menu-item">
                                    <a href="oder-tracking.html" className="">
                                        <div className="text">Order tracking</div>
                                    </a>
                                </li>
                            </ul>
                        </li>

                        <li className={menuState[5] ? 'menu-item has-children active' : 'menu-item has-children'} >
                            <a href="#" onClick={() => { togglemenu(5) }}>
                                <div className="icon"><i className="icon-shopping-cart"></i></div>
                                <div className="text">POS</div>
                            </a>
                            <ul className="sub-menu">
                                <li className="sub-menu-item">
                                    <NavLink to="/pos">
                                        <div className="text">POS list</div>
                                    </NavLink>
                                </li>
                                <li className="sub-menu-item">
                                    <NavLink to="/pos/add">
                                        <div className="text">Add new POS</div>
                                    </NavLink>
                                </li>
                            </ul>
                        </li>

                        <li className={menuState[3] ? 'menu-item has-children active' : 'menu-item has-children'} >
                            <a href="#" onClick={() => { togglemenu(3) }}>
                                <div className="icon"><i className="icon-user"></i></div>
                                <div className="text">User</div>
                            </a>
                            <ul className="sub-menu">
                                <li className="sub-menu-item">
                                    <NavLink to="/user" end>
                                        <div className="text">All users</div>
                                    </NavLink>
                                </li>

                                <li className="sub-menu-item">
                                    <NavLink to="/user/add">
                                        <div className="text">Add new user</div>
                                    </NavLink>
                                </li>
                            </ul>
                        </li>

                    </ul>
                </div>



            </div>

        </div>

    );
}

export default MenuLeft;
