
import { Outlet } from "react-router-dom";
import { useState, useEffect, Suspense } from 'react';
import MenuLeft from './components/MenuLeft';
import MenuTop from './components/MenuTop';

function Layout({ Helmet, setCookie, ToastContainer, switchTheme, API, CDN }) {

  return (
    <div id="wrapper">

    <Helmet><title>SkuCloud</title></Helmet>
    
    <div id="page" className="">
      <div className="layout-wrap">
        <MenuLeft />
        <div className="section-content-right">
            <MenuTop switchTheme={switchTheme} setCookie={setCookie} API={API} CDN={ CDN } />
          <div className="main-content">
            <div className="main-content-inner">
                <Suspense fallback={ null }><Outlet /></Suspense>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
}

export default Layout;
