import React, { useState } from 'react'


function Login({ onLogin }) {
    
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

 

    const handleSubmit = async (event) => {
        event.preventDefault()

        let data = {
            'email': email,
            'password': password
        };

        onLogin(data);
        
    }
    
    return (
           
            <div id="page" className="">
                <div className="wrap-login-page">
                    <div className="flex-grow flex flex-column justify-center gap30">
                        <a href="index.html" id="site-logo-inner">
                            
                        </a>
                        <div className="login-box">
                            <div>
                                <h3>Login to account</h3>
                                <div className="body-text">Enter your email & password to login</div>
                            </div>
                            <form className="form-login flex flex-column gap24" onSubmit={handleSubmit}>
                                <fieldset className="email">
                                    <div className="body-title mb-10">Email address <span className="tf-color-1">*</span></div>
                                    <input className="flex-grow" type="email" placeholder="Enter your email address" name="email" tabIndex="0" value={email} onChange={(e) => setEmail(e.target.value)} aria-required="true" required="" />
                                </fieldset>
                                <fieldset className="password mb-20">
                                    <div className="body-title mb-10">Password <span className="tf-color-1">*</span></div>
                                    <input className="password-input" type="password" placeholder="Enter your password" name="password" tabIndex="0" value={password} onChange={(e) => setPassword(e.target.value)} aria-required="true" required="" />
                                    <span className="show-pass">
                                        <i className="icon-eye view"></i>
                                        <i className="icon-eye-off hide"></i>
                                    </span>
                            </fieldset>
                            <input type="submit" value="Submit" className="tf-button w-full" />
                   
                            </form>
                            
                     
                        </div>
                    </div>
                    
                </div>
            </div>
            
        
    
    
    );
  }
  
  export default Login;